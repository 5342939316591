import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Grid, Paper, TableCell, TableContainer, Table, TableHead, TableRow, TableBody, Avatar, Menu, MenuItem } from '@mui/material'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { People, Contacts, AccountCircle, AddTask, ChevronLeft, ChevronRight } from '@mui/icons-material'
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import axios from 'axios'
import { styled, useTheme } from '@mui/material/styles';



const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Dashboard = (props) => {

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [cardStates, setCardStates] = useState([
    { title: '126,5 K', body: 'Total Leads Number', purcentage: '24% more than last week' },
    { title: '12,5 K', body: 'Total sales', purcentage: '2% more than last week' },
    { title: '1 K', body: 'New Leads Submitions', purcentage: '10% more than last week' },
    { title: '40 ', body: 'Contacts on you list', purcentage: '5% more than last week' },

  ])


  const useStyles = makeStyles((theme) => ({
    Tables: {
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
      borderRadius: '25px',
    },
    logo: {
      maxWidth: 150,
      marginRight: '10px'
    },
    link: {
      textDecoration: 'none',
      color: '#bebebe'
    },
    linkActive: {
      color: '#000',
      textDecoration: 'none'
    }

  }));

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const chartData = [
    {
      name: 'Sun',
      Leads: 120,
      Contacts: 20,
      Tasks: 32,
    },
    {
      name: 'Mon',
      Leads: 80,
      Contacts: 25,
      Tasks: 45,
    },
    {
      name: 'Tue',
      Leads: 10,
      Contacts: 2,
      Tasks: 12,
    },
    {
      name: 'Wed',
      Leads: 180,
      Contacts: 50,
      Tasks: 63,
    },
    {
      name: 'Thu',
      Leads: 38,
      Contacts: 25,
      Tasks: 28,
    },
    {
      name: 'Fri',
      Leads: 10,
      Contacts: 1,
      Tasks: 5,
    },
    {
      name: 'Sat',
      Leads: 45,
      Contacts: 35,
      Tasks: 17,
    },
  ];

  const history = useHistory()

  const handleLogout = (e) => {

    e.preventDefault();

    axios.interceptors.request.use((config) => {
      const account_token = localStorage.getItem('account_token');
      config.headers.Authorization = `Bearer ${account_token}`;
      return config
    }, error => {
      return console.log(error)
    })

    const account_token = localStorage.getItem('account_token')
    axios.put('api/account/v1/signout', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + account_token
      },
    })
      .then(res => {
        if (res.data.status === 200) {
          localStorage.clear()
          history.push('/')
        }
      })
  }

  const [tasks, setTasks] = useState([])
  const getTasks = (e) => {

    axios.interceptors.request.use((config) => {
      const Token = localStorage.getItem('account_token')
      config.headers.Authorization = `Bearer ${Token}`
      return config
    }, error => {
      return Promise.reject(error)
    })

    const Token = localStorage.getItem('account_token')
    const account_id = localStorage.getItem('account_id')

    const data = {
      id: account_id
    }

    axios.post("api/account/v1/gettasksaccount", data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer` + Token
      }
    })
      .then(res => {
        if (res.status === 200) {
          setTasks(res.data.tasks.data)
          console.log(res.data.tasks.data)
          console.log(res.data.tasks.data)
        }
      })

  }

  const [Leads, setLeads] = useState([])

  const getLeads = (e) => {
    axios.interceptors.request.use((config) => {
      const Token = localStorage.getItem('account_token')
      config.headers.Authorization = `Bearer ${Token}`
      return config
    }, error => {
      return Promise.reject(error)
    })

    const Token = localStorage.getItem('account_token')

    axios.get('api/account/v1/leads', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer` + Token
      }
    })
      .then(res => {
        if (res.status === 200) {
          setLeads(res.data.leads.data)

        }
      })
  }

  useEffect(() => {
    getTasks()
    getLeads()
  }, [])


  return (
    <div>
      <AppBar position="fixed" color='inherit' elevation='0' open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar   alt='Current User' sx={{ width: 48, height: 48 }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem><LinkRouter className={classes.link} to='/Profile'>Profile</LinkRouter></MenuItem>
              <MenuItem onClick={handleLogout} ><LinkRouter to='/'>Sign Out</LinkRouter></MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <List>
          <ListItem button>
            <ListItemIcon>
              <DashboardRoundedIcon />
            </ListItemIcon>
            <ListItemText className={classes.linkActive}>Dashboard</ListItemText>
          </ListItem>
          <ListItem button >
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <LinkRouter to='/Leads' className={classes.link}><ListItemText>Leads</ListItemText></LinkRouter>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Contacts />
            </ListItemIcon>
            <ListItemText><LinkRouter to='/Contacts' className={classes.link}>Clients</LinkRouter></ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <AddTask />
            </ListItemIcon>
            <ListItemText><LinkRouter to='/Tasks' className={classes.link}>Tasks</LinkRouter></ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <Container>
        {/* <Grid container spacing={2}>
          <Grid item sx={{ textAlign: 'center', margin: '100px 0px 40px 50px' }} >
            <Typography variant='h4' component='div'>
              Analytics Overview
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6} >
          {cardStates.map(cardState => (
            <Grid item xs={12} md={3} lg={3}>
              <Paper elevation='0' className={classes.PaperONE} sx={{ backgroundColor: '#eefefe', borderRadius: '25px', textAlign: 'center', padding: '30px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;' }}>
                <Typography variant="h4" >
                  <strong>{cardState.title}</strong>
                </Typography>
                <Typography variant='subtitle1'>
                  {cardState.body}
                </Typography>
                <Typography variant='subtitle2'>
                  {cardState.purcentage}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid> */}
        <Grid container spacing={3} sx={{ marginTop: '100px' }}>
          <Grid item xs={12} sm={12} md={12} className={classes.Tables}>
            <Typography variant="h5">
              Latest Leads
            </Typography>
            <TableContainer>
              <Table aria-label='simple data'>
                <TableHead>
                  <TableRow>
                    <TableCell>Lead Name</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Lead email</TableCell>
                    <TableCell>Lead Phone</TableCell>
                    <TableCell>Lead Source</TableCell>
                    <TableCell>Lead Owner</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Leads.map((Lead) => (
                    <TableRow key={Lead.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        {Lead.first_name + ' ' + Lead.last_name}
                      </TableCell>
                      <TableCell>
                        {Lead.company}
                      </TableCell>
                      <TableCell>
                        {Lead.email}
                      </TableCell>
                      <TableCell>
                        {Lead.mobile}
                      </TableCell>
                      <TableCell>
                        {Lead.source}
                      </TableCell>
                      <TableCell>
                        {Lead.country}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Paper sx={{ marginBottom: '150px' }}></Paper>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={7} md={7} lg={7} className={classes.Tables}>
            <Typography variant="h5" color="initial">Latest Tasks</Typography>
            <TableContainer >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Priority</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks.map((task) => (
                    <TableRow key={task.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        {task.subject}
                      </TableCell>
                      <TableCell>
                        {task.due_date}
                      </TableCell>
                      <TableCell>
                        {task.status}
                      </TableCell>
                      <TableCell>
                        {task.priority}
                      </TableCell>
                      <TableCell>
                        {task.description}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={5} sx={{ minWidth: '250px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart width={300} height={100} data={chartData}>
                <XAxis dataKey='name' />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Leads" stroke="#00b4d8" strokeWidth={2} />
                <Line type='monotone' dataKey='Contacts' stroke="#005f73" strokeWidth={2} />
                <Line type='monotone' dataKey='Tasks' stroke="#a4133c" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
        <Paper sx={{ marginBottom: '150px' }}></Paper>
      </Container>
    </div>

  );
}

export default Dashboard;