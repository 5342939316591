import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Grid, Paper, Avatar, Menu, MenuItem, Collapse } from '@mui/material'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ChevronLeft, ChevronRight, AddTask, People, Contacts, Work, PersonAdd, Campaign, ExpandLess, ExpandMore, TaskAlt, DoneAll, Block, Add, Construction, Business } from '@mui/icons-material'
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import axios from 'axios'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import { styled, useTheme } from '@mui/material/styles';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { ListItemButton } from '@mui/material'





const useStyles = makeStyles((theme) => ({
    Tables: {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        borderRadius: '25px',
    },
    logo: {
        maxWidth: 150,
        marginRight: '10px'
    },
    link: {
        textDecoration: 'none',
        color: '#bebebe'
    },
    linkActive: {
        color: '#000',
        textDecoration: 'none'
    }
}))

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));



const AdminBoard = (props) => {

    const classes = useStyles();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const history = useHistory();

    const handleLogout = (e) => {

        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const admin_token = localStorage.getItem('admin_token');
            config.headers.Authorization = `Bearer ${admin_token}`;
            return config
        }, error => {
            return console.log(error)
        })

        const admin_token = localStorage.getItem('admin_token')
        axios.put('api/admin/v1/signout', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + admin_token
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.clear()
                    history.push('/Admin-Login')
                }
            })
    }

    const [Leads, setLeads] = useState([])

    const getLeads = (e) => {
        axios.interceptors.request.use((config) => {
            const Token = localStorage.getItem('admin_token')
            config.headers.Authorization = `Bearer ${Token}`
            return config
        }, error => {
            return Promise.reject(error)
        })

        const admin_token = localStorage.getItem('admin_token')

        axios.get('api/admin/v1/leads', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer` + admin_token
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setLeads(res.data.leads.data)
                }
            })
    }

    const [Accounts, setAccounts] = useState([])

    const getAccounts = () => {
        axios.interceptors.request.use((config) => {
            const admin_token = localStorage.getItem('admin_token')
            config.headers.Authorization = `Bearer ${admin_token}`;
            return config
        }, error => {
            console.log(error)
        })

        const admin_token = localStorage.getItem('admin_token')

        axios.get('api/admin/v1/getaccounts', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + admin_token
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    setAccounts(res.data.accounts.data)
                }
            })
    }


    useEffect(() => {
        getLeads();
        getAccounts()
    }, [])

    const Columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'first_name', headerName: 'First Name', width: 100 },
        { field: 'last_name', headerName: 'Last Name', width: 100 },
        { field: 'company', headerName: 'Company', width: 150 },
        { field: 'email', headerName: 'Lead Email', width: 150 },
        { field: 'mobile', headerName: 'Lead Mobile', width: 150 },
        { field: 'source', headerName: 'Lead Source', width: 150 },
        { field: 'country', headerName: 'Lead Country', width: 150 },
    ]
    const EmployeesColumns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'name', headerName: 'Full Name', width: 200 },
        { field: 'phone', headerName: 'Phone', width: 200 },
        { field: 'position', headerName: 'Position', width: 150 },
        { field: 'created_at', headerName: 'Creation Date', width: 250 },
    ]

    const [Employees, setEmployees] = React.useState(false);
    const [Tasks, setTasks] = useState(false)
    const [Campaigns, setCampaigns] = useState(false)
    const [Leadss, setLeadss] = useState(false)
    const [Projects, setProjects] = useState(false)
    const [Productss, setProductss] = useState(false)
    const handleEmployees = () => {
        setEmployees(!Employees);
    };
    const handleTasks = () => {
        setTasks(!Tasks);
    };
    const handleCampaigns = () => {
        setCampaigns(!Campaigns);
    };
    const handleLeadss = () => {
        setLeadss(!Leadss);
    };
    const handleProjects = () => {
        setProjects(!Projects)
    }
    const handleProducts = () => {
        setProductss(!Productss)
    }



    return (
        <div>
            <AppBar position="fixed" color='inherit' elevation='0' open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar alt='Current User' sx={{ width: 48, height: 48 }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem><LinkRouter className={classes.link} to='/Admin-Profile'>Profile</LinkRouter></MenuItem>
                            <MenuItem onClick={handleLogout} ><LinkRouter to='/'>Sign Out</LinkRouter></MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </DrawerHeader>
                <List>
                    <ListItem >
                        <ListItemIcon>
                            <DashboardRoundedIcon />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Admin-Dashboard' className={classes.linkActive}>Dashboard</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItemButton onClick={handleLeadss}>
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Leads" />
                        {Leadss ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Leadss} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Lead' className={classes.link}>New Lead</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <People />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Leads' className={classes.link}>Leads List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem >
                        <ListItemIcon>
                            <Contacts />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Admin-Contacts' className={classes.link}>Clients</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItemButton onClick={handleTasks}>
                        <ListItemIcon>
                            <AddTask />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Tasks" />
                        {Tasks ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Tasks} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <AddTask />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Task' className={classes.link}>New Task</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <TaskAlt />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Tasks' className={classes.link}>Tasks List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <DoneAll />
                                </ListItemIcon>
                                <ListItemText className={classes.link} >
                                    Finished Tasks
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleEmployees}>
                        <ListItemIcon>
                            <Work />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Employees" />
                        {Employees ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Employees} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/New-Employee' className={classes.link}>Add employee</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Work />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Accounts' className={classes.link}>Employees List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Block />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Blocked-Employees' className={classes.link}>Blocked</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleCampaigns}>
                        <ListItemIcon>
                            <Campaign />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Campaigns" />
                        {Campaigns ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Campaigns} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Campaign' className={classes.link}>New Campaign</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Campaign />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Campaigns' className={classes.link}>Campaigns List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleProjects}>
                        <ListItemIcon>
                            <Construction />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Projects" />
                        {Projects ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Projects} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Create-Project' className={classes.link}>New Project</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Construction />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Projects' className={classes.link}>Projects List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleProducts}>
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Products" />
                        {Productss ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Productss} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Create-Product' className={classes.link}>New Product</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Business />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Products' className={classes.link}>Products List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Drawer>

            <Container sx={{ marginTop: '100px' }}>
                <Grid container spacing={6} sx={{ my: 4 }}>
                    <Typography variant="h4">
                        Leads List
                    </Typography>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 8 }}>
                        <div style={{ height: 800, width: '100%' }}>
                            <DataGrid
                                className={classes.Tables}
                                rows={Leads}
                                columns={Columns}
                                checkboxSelection
                                selected
                                onSelectionModelChange={(NewSelectionModel) => {
                                    localStorage.setItem('lead_id', parseInt(NewSelectionModel))
                                    console.log(parseInt(NewSelectionModel))
                                }}
                                components={{
                                    Toolbar: GridToolbar
                                }}
                                componentsProps={{
                                    row: {
                                        onContextMenu: 'hi',
                                        style: { cursor: 'context-menu' },
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={6} sx={{ my: 4 }}>
                    <Typography variant="h4">
                        Employees List
                    </Typography>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 8 }}>
                        <div style={{ height: 800, width: '100%' }}>
                            <DataGrid
                                className={classes.Tables}
                                rows={Accounts}
                                columns={EmployeesColumns}
                                checkboxSelection
                                selected
                                onSelectionModelChange={(NewSelectionModel) => {
                                    localStorage.setItem('Employee_id', parseInt(NewSelectionModel))
                                    console.log(parseInt(NewSelectionModel))
                                }}
                                components={{
                                    Toolbar: GridToolbar
                                }}
                                componentsProps={{
                                    row: {
                                        onContextMenu: 'hi',
                                        style: { cursor: 'context-menu' },
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default AdminBoard;