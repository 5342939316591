import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Grid, Paper, Avatar, Menu, MenuItem } from '@mui/material'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { People, Contacts, AccountCircle, AddTask } from '@mui/icons-material'
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Logo from '../../../Assets/images/logo.png'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl, Select, InputLabel } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import Swal from 'sweetalert2'


const useStyles = makeStyles((theme) => ({
    Tables: {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        borderRadius: '25px',
    },
    logo: {
        maxWidth: 150,
        marginRight: '10px'
    },
    link: {
        textDecoration: 'none',
        color: '#bebebe'
    },
    linkActive: {
        color: '#000',
        textDecoration: 'none'
    }
}))

const NewTask = (props) => {

    const classes = useStyles();

    const drawerWidth = 240;

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div sx={{ backgroundColor: "#f9f9f9" }}>
            <Toolbar>
                <img className={classes.logo} src={Logo} alt="GulfLand" />
            </Toolbar>
            <List>
                <ListItem button>
                    <ListItemIcon>
                        <DashboardRoundedIcon />
                    </ListItemIcon>
                    <ListItemText><LinkRouter to='/Dashboard' className={classes.link}>Dashboard</LinkRouter></ListItemText>
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <People />
                    </ListItemIcon>
                    <ListItemText><LinkRouter to='/Leads' className={classes.link}>Leads</LinkRouter></ListItemText>
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <Contacts />
                    </ListItemIcon>
                    <ListItemText><LinkRouter to='/Contacts' className={classes.link}>Contacts</LinkRouter></ListItemText>
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <AddTask />
                    </ListItemIcon>
                    <ListItemText><LinkRouter to='/Tasks' className={classes.link}>Tasks</LinkRouter></ListItemText>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [dueDate, setDueDate] = useState(null);
    const [subject, setSubject] = useState(null);
    const [status, setStatus] = useState(null)
    const [priority, setPriority] = useState(null)
    const [owner, setOwner] = useState(null)
    const [dueDateError, setdueDateError] = useState('')
    const [priorityError, setpriorityError] = useState('')
    const [statusError, setstatusError] = useState('')
    const [subjectError, setsubjectError] = useState('')

    const history = useHistory()

    const handleAddTask = (e) => {
        e.preventDefault();

        setDueDate()

        axios.interceptors.request.use((config) => {
            const account_token = localStorage.getItem('account_token');
            config.headers.Authorization = `Bearer ${account_token}`
            return config
        }, error => {
            console.log(error)
        })

        const account_id = localStorage.getItem('account_id')

        const data = {
            subject: subject,
            due_date: dueDate,
            status: status,
            priority: priority,
            account_id: account_id,
        }

        const account_token = localStorage.getItem('account_token');
        axios.post('api/account/v1/createtask', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + account_token
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Task Was Added Successfully',
                        showCancelButton: true,
                        cancelButtonText: 'close',
                        cancelButtonColor: '#CC3333',
                        confirmButtonText: 'Add New',
                        confirmButtonColor: '#42ba96'
                    }).then(result => {
                        if (result.isConfirmed) {
                            history.push('/New-Lead')
                        } else if (result.isDenied) {
                            history.push('/Leads')
                        }
                    })
                } else if (res.data.status === 201) {
                    setpriorityError(res.data.errors.priority)
                    setstatusError(res.data.errors.status)
                    setdueDateError(res.data.errors.due_date)
                    setsubjectError(res.data.errors.subject)
                }
            })
    }


    const handleStatus = (event) => {
        setStatus(event.target.value);
    };
    const handlePriority = (event) => {
        setPriority(event.target.value)
    }
    const handleOwner = (event) => {
        setOwner(event.target.value)
    }
    const handleSubject = (event) => {
        setSubject(event.target.value)
    }

    return (
        <div>
            <AppBar
                position="absolute"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
                color='inherit'
                elevation='0'
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar   alt='Current User' sx={{ width: 48, height: 48 }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem><LinkRouter className={classes.link} to='/Profile'>Profile</LinkRouter></MenuItem>
                            <MenuItem><LinkRouter to='/'>Sign Out</LinkRouter></MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>

            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
            <Container component="main" sx={{ marginTop: "100px" }}>
                <form noValidate>
                    <Grid container spacing={2} columnSpacing={5}>
                        <Grid item xs={12} sm={6}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleSubject}
                                label='Subject'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {subjectError} </span>
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ marginTop: "15px" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Due Date"
                                    views={['year', 'month', 'day']}
                                    format='DD-MM-YYYY'
                                    disablePast='true'
                                    value={dueDate}
                                    onChange={(newValue) => {
                                        setDueDate(newValue);
                                        console.log(newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <span style={{ color: 'red' }}> {dueDateError} </span>
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ marginTop: "15px" }}>
                            {/* first name container */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Owner</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={owner}
                                    label="Status"
                                    onChange={handleOwner}
                                >
                                    <MenuItem value={1}>lakhdari sami</MenuItem>
                                    <MenuItem value={2}>Nadjib Belfellahi</MenuItem>
                                    <MenuItem value={3}>Yasser Attia</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* first name container */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={status}
                                    label="Status"
                                    onChange={handleStatus}
                                >
                                    <MenuItem value={'In Progress'}>In Progress</MenuItem>
                                    <MenuItem value={"Done"}>Done</MenuItem>
                                    <MenuItem value={'Late'}>Late</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{ color: 'red' }}> {statusError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* last name container */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={priority}
                                    label="Status"
                                    onChange={handlePriority}
                                >
                                    <MenuItem value={'Low'}>Low</MenuItem>
                                    <MenuItem value={'Medium'}>Medium</MenuItem>
                                    <MenuItem value={'High'}>High</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{ color: 'red' }}> {priorityError} </span>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {/* last name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                label='Description'
                                multiline
                                fullWidth
                                rows='5'
                            />
                        </Grid>
                    </Grid>
                    <Box textAlign='center'>
                        <Button size='large' variant='contained' sx={{ mt: 8, justifyContent: 'center', borderRadius: '24px' }} onClick={handleAddTask}>Add Task</Button>
                    </Box>
                </form>
            </Container>
        </div>
    );
}

export default NewTask;