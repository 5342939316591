import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Grid, Avatar, Menu, MenuItem, Collapse, ListItemButton } from '@mui/material'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { People, Contacts, ChevronLeft, ChevronRight, AddTask, Work, PersonAdd, Campaign, Add, ExpandLess, ExpandMore, Block, DoneAll, TaskAlt, Business } from '@mui/icons-material'
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Logo from '../../../Assets/images/logo.png'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import axios from 'axios'
import { Button, Card, Box, Divider, CardContent, CardActions, CardHeader, TextField, Input } from '@mui/material';
import Swal from 'sweetalert2'
import { styled, useTheme } from '@mui/material/styles';





const useStyles = makeStyles((theme) => ({
    Tables: {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        borderRadius: '25px',
    },
    logo: {
        maxWidth: 150,
        marginRight: '10px'
    },
    link: {
        textDecoration: 'none',
        color: '#bebebe'
    },
    linkActive: {
        color: '#000',
        textDecoration: 'none'
    }
}))


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));




const AdminLeadProfile = (props) => {

    const Input = styled('input')({
        display: 'none',
    });

    const classes = useStyles();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const history = useHistory()

    const handleLogout = (e) => {

        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const admin_token = localStorage.getItem('admin_token');
            config.headers.Authorization = `Bearer ${admin_token}`;
            return config
        }, error => {
            return console.log(error)
        })

        const admin_token = localStorage.getItem('admin_token')

        axios.put('api/admin/v1/signout', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + admin_token
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.clear()
                    history.push('/')
                }
            })
    }

    const [leads, setleads] = useState([])
    const [Owner, setOwner] = useState([])

    const getLeadInfo = (e) => {
        axios.interceptors.request.use((config) => {
            const admin_id = localStorage.getItem('admin_token')
            config.headers.Authorization = `Bearer ${admin_id}`
            return config
        }, error => {
            console.log(error)
        })

        const admin_token = localStorage.getItem('admin_token')
        const lead_id = localStorage.getItem('lead_id')

        const data = {
            id: lead_id
        }

        axios.post('api/admin/v1/getlead', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + admin_token
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    setleads(res.data.lead)
                    setOwner(res.data.lead.account.name)
                }
            })
    }

    useEffect(() => {
        getLeadInfo()
    }, [])

    const handleUpdateLeadProfile = (e) => {
        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const admin_token = localStorage.getItem('admin_token')
            config.headers.Authorization = `Bearer ${admin_token}`
            return config
        }, error => {
            console.log(error)
        })

        const admin_token = localStorage.getItem('admin_token')
        const lead_id = localStorage.getItem('lead_id')

        const data = {
            id: lead_id
        }

        axios.post('api/admin/v1/updatelead', data, {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer' + admin_token
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'uploaded succesfully',
                        body: 'Refresh Page to see Updates'
                    })
                }
            })
    }

    const [Employees, setEmployees] = React.useState(false);
    const [Tasks, setTasks] = useState(false)
    const [Campaigns, setCampaigns] = useState(false)
    const [Leadss, setLeadss] = useState(false)
    const [Productss, setProductss] = useState(false)
    const handleEmployees = () => {
        setEmployees(!Employees);
    };
    const handleTasks = () => {
        setTasks(!Tasks);
    };
    const handleCampaigns = () => {
        setCampaigns(!Campaigns);
    };
    const handleLeadss = () => {
        setLeadss(!Leadss);
    };
    const handleProducts = () => {
        setProductss(!Productss)
    }

    return (
        <div>
            <AppBar position="fixed" color='inherit' elevation='0' open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar   alt='Current User' sx={{ width: 48, height: 48 }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem><LinkRouter className={classes.link} to='/Admin-Profile'>Profile</LinkRouter></MenuItem>
                            <MenuItem onClick={handleLogout} ><LinkRouter to='/'>Sign Out</LinkRouter></MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </DrawerHeader>
                <List>
                    <ListItem >
                        <ListItemIcon>
                            <DashboardRoundedIcon />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Admin-Dashboard' className={classes.link}>Dashboard</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItemButton onClick={handleLeadss}>
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <ListItemText primary="Leads" />
                        {Leadss ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Leadss} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Lead' className={classes.link}>New Lead</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <People />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Leads' className={classes.link}>Leads List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem >
                        <ListItemIcon>
                            <Contacts />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Admin-Contacts' className={classes.link}>Clients</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItemButton onClick={handleTasks}>
                        <ListItemIcon>
                            <AddTask />
                        </ListItemIcon>
                        <ListItemText primary="Tasks" />
                        {Tasks ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Tasks} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <AddTask />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Task' className={classes.link}>New Task</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <TaskAlt />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Tasks' className={classes.link}>Tasks List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <DoneAll />
                                </ListItemIcon>
                                <ListItemText className={classes.link} >
                                    Finished Tasks
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleEmployees}>
                        <ListItemIcon>
                            <Work />
                        </ListItemIcon>
                        <ListItemText primary="Employees" />
                        {Employees ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Employees} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/New-Employee' className={classes.link}>Add employee</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Work />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Accounts' className={classes.link}>Employees List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Block />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Blocked-Employees' className={classes.link}>Blocked</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleCampaigns}>
                        <ListItemIcon>
                            <Campaign />
                        </ListItemIcon>
                        <ListItemText primary="Campaigns" />
                        {Campaigns ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Campaigns} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Campaign' className={classes.link}>New Campaign</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Campaign />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Campaigns' className={classes.link}>Campaigns List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleProducts}>
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Products" />
                        {Productss ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Productss} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Create-Product' className={classes.link}>New Product</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Business />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Products' className={classes.link}>Products List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Drawer>

            <Container>
                <Grid container spacing={6} sx={{ marginTop: '100px' }}>
                    <Grid item md={4}>
                        <Card>
                            <CardContent>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography
                                                color="textPrimary"
                                                gutterBottom
                                                variant="h4"
                                                align='center'
                                            >
                                                {leads.first_name + ' ' + leads.last_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Title: </strong>{leads.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Mobile: </strong>{leads.mobile}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Source: </strong>{leads.source}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Status: </strong>{leads.status}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Rating: </strong>{leads.rating}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>City: </strong>{leads.city}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Country: </strong>{leads.country}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Description: </strong>{leads.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Lead Owner: </strong> {Owner}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                            <Divider />
                        </Card>
                    </Grid>
                    <Grid item md={8}>
                        <form
                            autoComplete="off"
                            noValidate
                            {...props}
                        >
                            <Card>
                                <CardHeader
                                    subheader="The information can be edited"
                                    title="Lead Profile"
                                />
                                <Divider />
                                <CardContent>
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                value={leads.first_name}
                                                onChange='{handleChange}'
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                value={leads.last_name}
                                                onChange='{handleChange}'
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                value={leads.status}
                                                onChange='{handleChange}'
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                onChange='{handleChange}'
                                                value={leads.rating}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                multiline
                                                onChange='{handleChange}'
                                                value={leads.description}
                                                variant="outlined"
                                                rows='5'
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        p: 2
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled
                                    >
                                        Update details
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Grid>
                </Grid>
            </Container>

        </div >
    );
}

export default AdminLeadProfile;