import Dashboard from "./views/users/dashboard/Dashboard";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import SignIn from "./views/users/login/Login";
import Leads from './views/users/Leads/Leads'
import Contact from "./views/users/contacts/contacts";
import Accounts from "./views/admin/Accounts/Accounts";
import Tasks from "./views/users/Tasks/Tasks";
import NewTask from "./views/users/Tasks/NewTaskForm";
import NewLead from "./views/users/Leads/NewLeadForm";
import AdminBoard from "./views/admin/Dashboard/DashboardAdmin";
import NewUser from "./views/admin/Add user/AddUser";
import AdminLogin from "./views/admin/login/LoginAdmin";
import axios from 'axios';
import UserProfile from "./views/users/Profile/Profile";
import LeadProfile from "./views/users/Lead Profile/LeadProfile";
import AdminLeads from "./views/admin/AdminLeads/AdminLeads";
import AdminNewLead from "./views/admin/AdminLeads/NewLeadForm";
import AdminContact from "./views/admin/contacts/contacts";
import AdminTasks from "./views/admin/Tasks/Tasks";
import AdminNewTask from "./views/admin/Tasks/NewTaskForm";
import AdminProfile from './views/admin/profile/AdminProfile'
import AdminCampaigns from "./views/admin/campaign/AdminCampaign";
import AdminNewCampaign from "./views/admin/campaign/AdminNewCampaign";
import BlockedAccounts from "./views/admin/Accounts/BlockedAccounts";
import AdminEditCampaigns from "./views/admin/campaign/EditCampaigns";
import EditProfile from "./views/admin/Accounts/AdminAccountProfile";
import AdminLeadProfile from "./views/admin/AdminLeads/AdminLeadProfile";
import AdminProjects from "./views/admin/Projects/AdminProjects";
import CreateProject from "./views/admin/Projects/CreateProjects";
import AdminProducts from "./views/admin/Products/AdminProducts";
import CreateProduct from "./views/admin/Products/CreateProduct";
import EditClient from "./views/admin/contacts/AdminContactProfile";



axios.defaults.baseURL = 'https://sub.gulflandproperty.net/'
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers['Access-Control-Allow-Origin'] = '*'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path='/'>
            <SignIn />
          </Route>
          <Route path='/Dashboard'>
            <Dashboard />
          </Route>
          <Route path='/Leads'>
            <Leads />
          </Route>
          <Route path='/Contacts'>
            <Contact />
          </Route>
          <Route path='/Accounts'>
            <Accounts />
          </Route>
          <Route path='/Tasks'>
            <Tasks />
          </Route>
          <Route path='/New-Task'>
            <NewTask />
          </Route>
          <Route path='/New-Lead'>
            <NewLead />
          </Route>
          <Route path='/Admin-Dashboard'>
            <AdminBoard />
          </Route>
          <Route path='/New-Employee'>
            <NewUser />
          </Route>
          <Route path='/Admin-Login'>
            <AdminLogin />
          </Route>
          <Route path='/Profile'>
            <UserProfile />
          </Route>
          <Route path='/Lead-Profile'>
            <LeadProfile />
          </Route>
          <Route path ='/Admin-Leads'>
            <AdminLeads />
          </Route>
          <Route path='/Admin-New-Lead'>
            <AdminNewLead />
          </Route>
          <Route path='/Admin-Contacts'>
            <AdminContact />
          </Route>
          <Route path='/Admin-Tasks'>
            <AdminTasks />
          </Route>
          <Route path='/Admin-New-Task'>
            <AdminNewTask />
          </Route>
          <Route path='/Admin-Profile'>
            <AdminProfile />
          </Route>
          <Route path='/Admin-Campaigns'>
            <AdminCampaigns />
          </Route>
          <Route path='/Admin-New-Campaign'>
            <AdminNewCampaign />
          </Route>
          <Route path='/Blocked-Employees'>
            <BlockedAccounts/>
          </Route>
          <Route path='/Update-Campaign'>
            <AdminEditCampaigns />
          </Route>
          <Route path='/Update-Employee-informations'>
            <EditProfile />
          </Route>
          <Route path='/Admin-Lead-Profile'>
            <AdminLeadProfile />
          </Route>
          <Route path='/Admin-Projects'>
            <AdminProjects/>
          </Route>
          <Route path='/Create-Project'>
            <CreateProject/>
          </Route>
          <Route path='/Admin-Products'>
            <AdminProducts />
          </Route>
          <Route path='/Create-Product'>
            <CreateProduct/>
          </Route>
          <Route path='/Update-Client-informations'>
            <EditClient/>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
