import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Grid, Avatar, Menu, MenuItem } from '@mui/material'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { People, Contacts, AccountCircle, AddTask } from '@mui/icons-material'
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Logo from '../../../Assets/images/logo.png'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import axios from 'axios'
import { Button, Card, Box, Divider, CardContent, CardActions, CardHeader, TextField, Input } from '@mui/material';
import { styled } from '@mui/styles'
import Swal from 'sweetalert2'




const useStyles = makeStyles((theme) => ({
    Tables: {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        borderRadius: '25px',
    },
    logo: {
        maxWidth: 150,
        marginRight: '10px'
    },
    link: {
        textDecoration: 'none',
        color: '#bebebe'
    },
    linkActive: {
        color: '#000',
        textDecoration: 'none'
    }
}))



const LeadProfile = (props) => {

    const Input = styled('input')({
        display: 'none',
    });

    const classes = useStyles();

    const drawerWidth = 240;

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div sx={{ backgroundColor: "#f9f9f9" }}>
            <Toolbar>
                <img className={classes.logo} src={Logo} alt="GulfLand" />
            </Toolbar>
            <List>
                <ListItem button>
                    <ListItemIcon>
                        <DashboardRoundedIcon />
                    </ListItemIcon>
                    <ListItemText><LinkRouter to='/Dashboard' className={classes.link}>Dashboard</LinkRouter></ListItemText>
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <People />
                    </ListItemIcon>
                    <ListItemText><LinkRouter to='/Leads' className={classes.link}>Leads</LinkRouter></ListItemText>
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <Contacts />
                    </ListItemIcon>
                    <ListItemText className={classes.link}><LinkRouter to='/Contacts' className={classes.link}>Contacts</LinkRouter></ListItemText>
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <AddTask />
                    </ListItemIcon>
                    <ListItemText><LinkRouter to='/Tasks' className={classes.link}>Tasks</LinkRouter></ListItemText>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const history = useHistory()

    const handleLogout = (e) => {

        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const account_token = localStorage.getItem('account_token');
            config.headers.Authorization = `Bearer ${account_token}`;
            return config
        }, error => {
            return console.log(error)
        })

        const account_token = localStorage.getItem('account_token')

        axios.put('api/account/v1/signout', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + account_token
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.clear()
                    history.push('/')
                }
            })
    }

    const [leads, setleads] = useState([])
    const [Owner, setOwner] = useState([])

    const getLeadInfo = (e) => {
        axios.interceptors.request.use((config) => {
            const account_id = localStorage.getItem('account_token')
            config.headers.Authorization = `Bearer ${account_id}`
            return config
        }, error => {
            console.log(error)
        })

        const account_token = localStorage.getItem('account_token')
        const lead_id = localStorage.getItem('lead_id')

        const data = {
            id: lead_id
        }

        axios.post('api/account/v1/updatelead', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + account_token
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    setleads(res.data.lead)
                    setOwner(res.data.lead.account)
                    console.log(res.data.lead.account)
                }
            })
    }

    useEffect(() => {
        getLeadInfo()
    }, [])

    const handleUpdateLeadProfile = (e) => {
        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const account_token = localStorage.getItem('account_token')
            config.headers.Authorization = `Bearer ${account_token}`
            return config
        }, error => {
            console.log(error)
        })

        const account_token = localStorage.getItem('account_token')
        const lead_id = localStorage.getItem('lead_id')

        const data = {
            id: lead_id
        }

        axios.post('api/account/v1/updatelead', data, {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer' + account_token
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'uploaded succesfully',
                        body: 'Refresh Page to see Updates'
                    })
                }
            })
    }

    return (
        <div>
            <AppBar
                position="absolute"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
                color='inherit'
                elevation='0'
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar   alt='Current User' sx={{ width: 48, height: 48 }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem><LinkRouter className={classes.link} to='/Profile'>Profile</LinkRouter></MenuItem>
                            <MenuItem><LinkRouter onClick={handleLogout} className={classes.link}>Sign Out</LinkRouter></MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>

            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>

            <Container>
                <Grid container spacing={6} sx={{ marginTop: '100px' }}>
                    <Grid item md={4}>
                        <Card>
                            <CardContent>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            height: 100,
                                            width: 100
                                        }}
                                    />
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography
                                                color="textPrimary"
                                                gutterBottom
                                                variant="h4"
                                            >
                                                {leads.first_name + ' ' + leads.last_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                        <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Title: </strong>{leads.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Mobile: </strong>{leads.mobile}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Source: </strong>{leads.source}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Status: </strong>{leads.status}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Rating: </strong>{leads.rating}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>City: </strong>{leads.city}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Country: </strong>{leads.country}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Description: </strong>{leads.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body1"
                                            >
                                                <strong>Lead Owber: </strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                            </Box>
                        </CardContent>
                        <Divider />
                        <CardActions sx={{ justifyContent: 'center' }}>
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" />
                                <Button variant="text" fullWidth>
                                    Upload Profile Picture
                                </Button>
                            </label>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item md={8}>
                    <form
                        autoComplete="off"
                        noValidate
                        {...props}
                    >
                        <Card>
                            <CardHeader
                                subheader="The information can be edited"
                                title="Lead Profile"
                            />
                            <Divider />
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            value={leads.first_name}
                                            onChange='{handleChange}'
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            value={leads.last_name}
                                            onChange='{handleChange}'
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            value={leads.status}
                                            onChange='{handleChange}'
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            onChange='{handleChange}'
                                            value={leads.rating}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            multiline
                                            onChange='{handleChange}'
                                            value={leads.description}
                                            variant="outlined"
                                            rows='5'
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled
                                >
                                    Update details
                                </Button>
                            </Box>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </Container>

        </div >
    );
}

export default LeadProfile;