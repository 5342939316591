import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Grid, Paper, Avatar, Menu, MenuItem, Divider, ListItemButton, Collapse } from '@mui/material'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { People, Contacts, ChevronLeft, ChevronRight, AddTask, Work, PersonAdd, Campaign, ExpandLess, ExpandMore, TaskAlt, Block, Add, DoneAll, Construction, Business } from '@mui/icons-material'
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormControl, Select, InputLabel } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import Swal from 'sweetalert2'
import { styled, useTheme } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'


const useStyles = makeStyles((theme) => ({
    Tables: {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        borderRadius: '25px',
    },
    logo: {
        maxWidth: 150,
        marginRight: '10px'
    },
    link: {
        textDecoration: 'none',
        color: '#bebebe'
    },
    linkActive: {
        color: '#000',
        textDecoration: 'none'
    }
}))


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const AdminNewLead = (props) => {

    const classes = useStyles();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const container = window !== undefined ? () => window().document.body : undefined;

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [company, setCompany] = useState(null);
    const [salutation, setSalutation] = useState(null);
    const [firstName, setFirstName] = useState(null)
    const [LastName, setLastName] = useState(null)
    const [title, setTitle] = useState(null)
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [mobile, setMobile] = useState(null)
    const [website, setWebsite] = useState(null)
    const [source, setSource] = useState(null);
    const [status, setStatus] = useState(null);
    const [WorkField, setWorkField] = useState(null)
    const [BirthDay, setBirthDay] = useState(null)
    const [Permit, setPermit] = useState(null)
    const [annualRevenue, setAnnualRevenue] = useState(null)
    const [rating, setRating] = useState(null)
    const [secondEmail, setSecondEmail] = useState(null);
    const [city, setCity] = useState(null)
    const [state, setState] = useState(null)
    const [country, setCountry] = useState(null)
    const [description, setDescription] = useState(null)
    const [PreferredLang, setPreferredLang] = useState(null)
    const [owner, setOwner] = useState(null)
    const [companyError, setcompanyError] = useState("")
    const [cityError, setcityError] = useState('')
    const [countryError, setcountryError] = useState('')
    const [descError, setdescError] = useState('')
    const [mailError, setmailError] = useState('')
    const [firstNameError, setfirstNameError] = useState('')
    const [lastNameError, setlastNameError] = useState('')
    const [mobileError, setmobileError] = useState('')
    const [phoneError, setphoneError] = useState('')
    const [ratingError, setratingError] = useState('')
    const [sourceError, setsourceError] = useState('')
    const [stateError, setstateError] = useState('')
    const [statusError, setstatusError] = useState('')
    const [titleError, settitleError] = useState('')
    const [websiteError, setwebsiteError] = useState('')
    const [WorkFieldError, setWorkFieldError] = useState('')
    const [PreferredLangError, setPreferredLangError] = useState('')
    const [PermitError, setPermitError] = useState('')
    const [BirthDayError, setBirthDayError] = useState('')

    const handleStatus = (event) => {
        setStatus(event.target.value);
    };
    const handleSalutation = (event) => {
        setSalutation(event.target.value)
    }
    const handleSource = (event) => {
        setSource(event.target.value)
    }
    const handleWorkField = (event) => {
        setWorkField(event.target.value)
    }
    const handleRating = (event) => {
        setRating(event.target.value)
    }
    const handleCompany = (event) => {
        setCompany(event.target.value)
    }
    const handleFirstName = (event) => {
        setFirstName(event.target.value)
    }
    const handleLastName = (event) => {
        setLastName(event.target.value)
    }
    const handleTitle = (event) => {
        setTitle(event.target.value)
    }
    const handleEmail = (event) => {
        setEmail(event.target.value)
    }
    const handlePhone = (event) => {
        setPhone(event.target.value)
    }
    const handleMobile = (event) => {
        setMobile(event.target.value)
    }
    const handleWebsite = (event) => {
        setWebsite(event.target.value)
    }
    const handleAnnualRevenue = (event) => {
        setAnnualRevenue(event.target.value)
    }
    const handleSecondEmail = (event) => {
        setSecondEmail(event.target.value)
    }
    const handleCity = (event) => {
        setCity(event.target.value)
    }
    const handleState = (event) => {
        setState(event.target.value)
    }
    const handleCountry = (event) => {
        setCountry(event.target.value)
    }
    const handleDescription = (event) => {
        setDescription(event.target.value)
    }
    const handlePreferredLang = (e) => {
        setPreferredLang(e.target.value)
    }
    const handlePermit = (e) => {
        setPermit(e.target.value)
    }

    const history = useHistory()

    const handleLogout = (e) => {

        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const account_token = localStorage.getItem('account_token');
            config.headers.Authorization = `Bearer ${account_token}`;
            return config
        }, error => {
            return console.log(error)
        })

        const account_token = localStorage.getItem('account_token')
        axios.put('api/admin/v1/signout', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + account_token
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.clear()
                    history.push('/')
                }
            })
    }

    const handleAddLead = (e) => {
        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const admin_token = localStorage.getItem('admin_token')
            config.headers.Authorization = `Bearer ${admin_token}`
            return config
        }, error => {
            console.log(error)
        })

        const admin_token = localStorage.getItem('admin_token')
        const admin_id = localStorage.getItem('admin_id')

        const data = {
            company: company,
            first_name: firstName,
            last_name: LastName,
            title: title,
            email: email,
            mobile: mobile,
            source: source,
            status: status,
            rating: rating,
            city: city,
            state: state,
            country: country,
            description: description,
            admin_id: admin_id,
            phone: phone,
            website: website,
            annualRevenue: annualRevenue,
            secondEmail: secondEmail,
            work_field: WorkField,
            preferred_language: PreferredLang,
            permit: Permit,
            date_of_birthday: BirthDay
        }

        axios.post('api/admin/v1/createlead', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + admin_token,
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Lead Was Added Successfully',
                        showCancelButton: true,
                        cancelButtonText: 'close',
                        cancelButtonColor: '#CC3333',
                        confirmButtonText: 'Add New',
                        confirmButtonColor: '#42ba96'
                    }).then(result => {
                        if (result.isConfirmed) {
                            history.push('/New-Lead')
                        } else if (result.isDenied) {
                            history.push('/Leads')
                        }
                    })
                } else if (res.data.status === 201) {
                    setcityError(res.data.errors.city)
                    setcompanyError(res.data.errors.company)
                    setcountryError(res.data.errors.country)
                    setdescError(res.data.errors.description)
                    setmailError(res.data.errors.email)
                    setfirstNameError(res.data.errors.first_name)
                    setlastNameError(res.data.errors.last_name)
                    setmobileError(res.data.errors.mobile)
                    setphoneError(res.data.errors.phone)
                    setratingError(res.data.errors.rating)
                    setsourceError(res.data.errors.source)
                    setstateError(res.data.errors.state)
                    setstatusError(res.data.errors.status)
                    settitleError(res.data.errors.title)
                    setWorkFieldError(res.data.errors.work_field)
                    setPreferredLangError(res.data.errors.preferred_language)
                    setPermitError(res.data.errors.permit)
                    setBirthDayError(res.data.errors.date_of_birthday)
                }
            }, error => {
                console.log(error)
            })
    }

    const [Employees, setEmployees] = React.useState(false);
    const [Tasks, setTasks] = useState(false)
    const [Campaigns, setCampaigns] = useState(false)
    const [Leadss, setLeadss] = useState(false)
    const [Projects, setProjects] = useState(false)
    const [Productss, setProductss] = useState(false)
    const handleEmployees = () => {
        setEmployees(!Employees);
    };
    const handleTasks = () => {
        setTasks(!Tasks);
    };
    const handleCampaigns = () => {
        setCampaigns(!Campaigns);
    };
    const handleLeadss = () => {
        setLeadss(!Leadss);
    };
    const handleProjects = () => {
        setProjects(!Projects)
    }
    const handleProducts = () => {
        setProductss(!Productss)
    }


    return (
        <div>
            <AppBar position="fixed" color='inherit' elevation='0' open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar alt='Current User' sx={{ width: 48, height: 48 }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem><LinkRouter className={classes.link} to='/Admin-Profile'>Profile</LinkRouter></MenuItem>
                            <MenuItem onClick={handleLogout} ><LinkRouter to='/'>Sign Out</LinkRouter></MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </DrawerHeader>
                <List>
                    <ListItem >
                        <ListItemIcon>
                            <DashboardRoundedIcon />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Admin-Dashboard' className={classes.link}>Dashboard</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItemButton onClick={handleLeadss}>
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Leads" />
                        {Leadss ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Leadss} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Lead' className={classes.linkActive}>New Lead</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <People />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Leads' className={classes.link}>Leads List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem >
                        <ListItemIcon>
                            <Contacts />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Admin-Contacts' className={classes.link}>Clients</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItemButton onClick={handleTasks}>
                        <ListItemIcon>
                            <AddTask />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Tasks" />
                        {Tasks ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Tasks} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <AddTask />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Task' className={classes.link}>New Task</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <TaskAlt />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Tasks' className={classes.link}>Tasks List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <DoneAll />
                                </ListItemIcon>
                                <ListItemText className={classes.link} >
                                    Finished Tasks
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleEmployees}>
                        <ListItemIcon>
                            <Work />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Employees" />
                        {Employees ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Employees} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/New-Employee' className={classes.link}>Add employee</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Work />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Accounts' className={classes.link}>Employees List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Block />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Blocked-Employees' className={classes.link}>Blocked</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleCampaigns}>
                        <ListItemIcon>
                            <Campaign />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Campaigns" />
                        {Campaigns ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Campaigns} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Campaign' className={classes.link}>New Campaign</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Campaign />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Campaigns' className={classes.link}>Campaigns List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleProjects}>
                        <ListItemIcon>
                            <Construction />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Projects" />
                        {Projects ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Projects} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Create-Project' className={classes.link}>New Project</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Construction />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Projects' className={classes.link}>Projects List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleProducts}>
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Products" />
                        {Productss ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Productss} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Create-Product' className={classes.link}>New Product</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Business />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Products' className={classes.link}>Products List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Drawer>
            <Container component="main" sx={{ marginTop: "100px" }}>
                <form noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2} sx={{ marginTop: "15px" }}>
                            {/* first name container */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Salutation</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={salutation}
                                    label="Status"
                                    onChange={handleSalutation}
                                >
                                    <MenuItem value={'Mr.'}>Mr.</MenuItem>
                                    <MenuItem value={'Ms.'}>Ms.</MenuItem>
                                    <MenuItem value={'Dr.'}>Dr.</MenuItem>
                                    <MenuItem value={'Prof.'}>Prof.</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleFirstName}
                                label='First Name'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {firstNameError} </span>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleLastName}
                                label='Last Name'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {lastNameError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onBlur={handleCompany}
                                label='Company'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {companyError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleTitle}
                                label='Title'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {titleError} </span>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleEmail}
                                label='Email'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {mailError} </span>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onBlur={handlePhone}
                                label='Phone'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {phoneError} </span>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleMobile}
                                label='Mobile'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {mobileError} </span>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onBlur={handleWebsite}
                                label='Website'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {websiteError} </span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">Source</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={source}
                                    label="Status"
                                    onChange={handleSource}
                                >
                                    <MenuItem value={'Advertisement'}>Advertisement</MenuItem>
                                    <MenuItem value={'Cold Call'}>Cold Call</MenuItem>
                                    <MenuItem value={'Employee Referral'}>Employee Referral</MenuItem>
                                    <MenuItem value={'External Referral'}>External Referral</MenuItem>
                                    <MenuItem value={'Online Store'}>Online Store</MenuItem>
                                    <MenuItem value={'Twitter'}>Twitter</MenuItem>
                                    <MenuItem value={'Facebook'}>Facebook</MenuItem>
                                    <MenuItem value={'Public Relations'}>Public Relations</MenuItem>
                                    <MenuItem value={'Sales Email Alias'}>Sales Email Alias</MenuItem>
                                    <MenuItem value={'Seminar Partner'}>Seminar Partner</MenuItem>
                                    <MenuItem value={'Internal Seminar'}>Internal Seminar</MenuItem>
                                    <MenuItem value={'Trade Show'}>Trade Show</MenuItem>
                                    <MenuItem value={'Web Download'}>Web Download</MenuItem>
                                    <MenuItem value={'Web Research'}>Web Research</MenuItem>
                                    <MenuItem value={'Chat'}>Chat</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{ color: 'red' }}> {sourceError} </span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={status}
                                    label="Status"
                                    onChange={handleStatus}
                                >
                                    <MenuItem value={'Attempted to Contact'}>Attempted to Contact</MenuItem>
                                    <MenuItem value={'Contact in Future'}>Contact in Future</MenuItem>
                                    <MenuItem value={'Contacted'}>Conctacted</MenuItem>
                                    <MenuItem value={'Junk Lead'}>Junk Lead</MenuItem>
                                    <MenuItem value={'Lost Lead'}>Lost Lead</MenuItem>
                                    <MenuItem value={'Not Contacted'}>Not Contacted</MenuItem>
                                    <MenuItem value={'Pre-Qualified'}>Pre-Qualified</MenuItem>
                                    <MenuItem value={'Not Qualified'}>Not Qualified</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{ color: 'red' }}> {statusError} </span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Rating</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={rating}
                                    label="Status"
                                    onChange={handleRating}
                                >
                                    <MenuItem value={'Acquired'}>Acquired</MenuItem>
                                    <MenuItem value={'Active'}>Active</MenuItem>
                                    <MenuItem value={'Market Failed'}>Market Failed</MenuItem>
                                    <MenuItem value={'Project Cancelled'}>Project Cancelled</MenuItem>
                                    <MenuItem value={'Shut Down'}>Shut Down</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{ color: 'red' }}> {ratingError} </span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onBlur={handleWorkField}
                                label='Work Field'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {WorkFieldError} </span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onBlur={handleAnnualRevenue}
                                label='Annual Revenue'
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onBlur={handleSecondEmail}
                                label='Second Email'
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">Permit</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={Permit}
                                    label="Permit"
                                    onChange={handlePermit}
                                >
                                    <MenuItem value={'Resident'}>Resident</MenuItem>
                                    <MenuItem value={'Tourist'}>Tourist</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{ color: 'red' }}> {PermitError} </span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">Preferred language</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={PreferredLang}
                                    label="Status"
                                    onChange={handlePreferredLang}
                                >
                                    <MenuItem value={'ar'}>Arabic</MenuItem>
                                    <MenuItem value={'en'}>English</MenuItem>
                                    <MenuItem value={'ru'}>Russian</MenuItem>
                                    <MenuItem value={'fr'}>French</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{ color: 'red' }}> {PreferredLangError} </span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Birth day"
                                    views={['year', 'month', 'day']}
                                    inputFormat="yyyy-MM-dd"
                                    value={BirthDay}
                                    onChange={(BirthDay) => {
                                        setBirthDay(BirthDay.toLocaleDateString("sv-SE"))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <span style={{ color: 'red' }}> {BirthDayError} </span>
                        </Grid>
                        <Divider fullWidth sx={{ color: 'green', width: "100%", my: 2 }} />
                        <Grid item xs={12} sm={4}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onBlur={handleState}
                                label='State'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {stateError} </span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleCity}
                                label='City'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {cityError} </span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleCountry}
                                label='Country'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {countryError} </span>
                        </Grid>
                        <Divider fullWidth sx={{ color: 'green', width: "100%", my: 2 }} />
                        <Grid item xs={12} sm={12}>
                            {/* last name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                label='Description'
                                onBlur={handleDescription}
                                multiline
                                fullWidth
                                rows='5'
                            />
                            <span style={{ color: 'red' }}> {descError} </span>
                        </Grid>
                    </Grid>
                    <Box textAlign='center'>
                        <Button size='large' variant='contained' sx={{ my: 8, justifyContent: 'center', borderRadius: '24px' }} onClick={handleAddLead}>Add Lead</Button>
                    </Box>
                </form>
            </Container>
        </div>
    );
}

export default AdminNewLead;