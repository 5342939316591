import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Grid, Avatar, Menu, MenuItem, Button } from '@mui/material'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { People, Contacts, AccountCircle, AddTask, ChevronLeft, ChevronRight } from '@mui/icons-material'
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Logo from '../../../Assets/images/logo.png'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import axios from 'axios'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Swal from 'sweetalert2'
import { styled, useTheme } from '@mui/material/styles';




const useStyles = makeStyles((theme) => ({
    Tables: {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        borderRadius: '25px',
    },
    logo: {
        maxWidth: 150,
        marginRight: '10px'
    },
    root: {
        display: 'flex',
    },
    link: {
        textDecoration: 'none',
        color: '#bebebe'
    },
    linkActive: {
        color: '#000',
        textDecoration: 'none'
    }
}))

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));



const Leads = (props) => {

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [selectedRow, setSelectedRow] = useState();

    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute('data-id')));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };



    const classes = useStyles();

    const drawerWidth = 240;

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setContextMenu(null);
    };

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const [Leads, setLeads] = useState([])

    const history = useHistory()

    const getLeads = (e) => {
        axios.interceptors.request.use((config) => {
            const Token = localStorage.getItem('account_token')
            config.headers.Authorization = `Bearer ${Token}`
            return config
        }, error => {
            return Promise.reject(error)
        })

        const Token = localStorage.getItem('account_token')

        axios.get('api/account/v1/leads', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer` + Token
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setLeads(res.data.leads.data)
                }
            })
    }

    const [isLoading, setisLoading] = useState(true)

    useEffect(() => {
        getLeads();
        setisLoading(false);
    }, [])

    const handleLogout = (e) => {

        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const account_token = localStorage.getItem('account_token');
            config.headers.Authorization = `Bearer ${account_token}`;
            return config
        }, error => {
            return console.log(error)
        })

        const account_token = localStorage.getItem('account_token')
        axios.put('api/account/v1/signout', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + account_token
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.clear()
                    history.push('/')
                }
            })
    }

    const Columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'first_name', headerName: 'First Name', width: 100 },
        { field: 'last_name', headerName: 'Last Name', width: 100 },
        { field: 'company', headerName: 'Company', width: 150 },
        { field: 'email', headerName: 'Lead Email', width: 150 },
        { field: 'mobile', headerName: 'Lead Mobile', width: 150 },
        { field: 'source', headerName: 'Lead Source', width: 150 },
        { field: 'country', headerName: 'Lead Country', width: 150 },
    ]

    const handleConvertContact = (e) => {

        axios.interceptors.request.use((config) => {
            const account_token = localStorage.getItem('account_token');
            config.headers.Authorization = `Bearer ${account_token}`;
            return config
        }, error => {
            console.log(error)
        })

        const account_token = localStorage.getItem('account_token')

        const data = {
            id: LeadId,
            account_id: localStorage.getItem('account_id')
        }

        axios.post(`api/account/v1/convertleadtocontact`, data, {
            Headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + account_token
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    history.go(0)
                }
            })
    }

    const [LeadId, setLeadId] = useState(null)

    const handleDeleteLead = (e) => {

        axios.interceptors.request.use((config) => {
            const account_token = localStorage.getItem('account_token')
            config.headers.Authorization = `Bearer ${account_token}`
            return config
        }, error => {
            console.log(error)
        })

        const account_token = localStorage.getItem('account_token')

        const data = {
            id: LeadId
        }

        axios.post('api/account/v1/deletelead', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + account_token
            }
        })
            .then(res => {
                if (res.status === 200) {
                    console.log('done');
                    history.go(0)
                }
            })
    }

    const popUpDelete = (e) => {

        Swal.fire({
            icon: 'warning',
            title: 'Lead Delete',
            text: 'Are you Sure you want to delete Lead ?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Delete',
            confirmButtonColor: '#df4759'

        })
            .then(result => {
                if (result.isConfirmed) {
                    handleDeleteLead()
                }
            })
    }
    const popUpConvertLead = (e) => {

        Swal.fire({
            icon: 'warning',
            title: 'Converting Lead',
            text: 'Are you Sure you want to convert this lead To contact ?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Convert',
            confirmButtonColor: '#20c997'

        })
            .then(result => {
                if (result.isConfirmed) {
                    handleConvertContact()
                }
            })
    }

    return (
        <div>
            <AppBar position="fixed" color='inherit' elevation='0' open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    <div>
                        <Button color='primary' variant='outlined'><LinkRouter to='/New-Lead' className={classes.link}>Create Lead</LinkRouter></Button>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar   alt='Current User' sx={{ width: 48, height: 48 }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem><LinkRouter className={classes.link} to='/Profile'>Profile</LinkRouter></MenuItem>
                            <MenuItem onClick={handleLogout} ><LinkRouter to='/'>Sign Out</LinkRouter></MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </DrawerHeader>
                <List>
                    <ListItem button>
                        <ListItemIcon>
                            <DashboardRoundedIcon />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Dashboard' className={classes.link}>Dashboard</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItem button >
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <LinkRouter to='/Leads' className={classes.linkActive}><ListItemText>Leads</ListItemText></LinkRouter>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <Contacts />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Contacts' className={classes.link}>Clients</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <AddTask />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Tasks' className={classes.link}>Tasks</LinkRouter></ListItemText>
                    </ListItem>
                </List>
            </Drawer>

            <Container sx={{ marginTop: '100px' }}>
                <Typography
                    sx={{ mb: 3 }}
                    variant="h4"
                >
                    Leads List
                </Typography>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: 8, mb: 8 }}>
                        <div style={{ height: 800, width: '100%' }}>
                            <DataGrid
                                className={classes.Tables}
                                rows={Leads}
                                columns={Columns}
                                checkboxSelection
                                selected
                                onSelectionModelChange={(NewSelectionModel) => {
                                    setLeadId(parseInt(NewSelectionModel))
                                    localStorage.setItem('lead_id', parseInt(NewSelectionModel))
                                    console.log(parseInt(NewSelectionModel))
                                }}
                                components={{
                                    Toolbar: GridToolbar
                                }}
                                componentsProps={{
                                    row: {
                                        onContextMenu: handleContextMenu,
                                        style: { cursor: 'context-menu' },
                                    },
                                }}
                            />
                        </div>

                        <Menu
                            open={contextMenu !== null}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                contextMenu !== null
                                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                    : undefined
                            }
                            componentsProps={{
                                root: {
                                    onContextMenu: (e) => {
                                        e.preventDefault();
                                        handleClose();
                                    },
                                },
                            }}
                        >
                            <MenuItem onClick={popUpConvertLead}><span className={classes.link}>Convert to Client</span></MenuItem>
                            <MenuItem><LinkRouter to='/Lead-Profile' className={classes.link}>Edit Lead information</LinkRouter></MenuItem>
                            <MenuItem onClick={popUpDelete}> <span className={classes.link}>Delete Lead</span> </MenuItem>
                        </Menu>

                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Leads;