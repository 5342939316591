import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Grid, Avatar, Menu, MenuItem, Divider, ListItemButton } from '@mui/material'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Work, People, AddTask, Contacts, PersonAdd, Campaign, ChevronLeft, ChevronRight, InboxOutlined, ExpandLess, ExpandMore, StarBorder, Block, TaskAlt, DoneAll, Add, Construction, Business } from '@mui/icons-material'
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import { Link as LinkRouter } from 'react-router-dom'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormControl, Select, InputLabel } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import { styled, useTheme } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import Collapse from '@mui/material/Collapse';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata'


const useStyles = makeStyles((theme) => ({
    Tables: {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        borderRadius: '25px',
    },
    logo: {
        maxWidth: 150,
        marginRight: '10px'
    },
    link: {
        textDecoration: 'none',
        color: '#bebebe'
    },
    linkActive: {
        color: '#000',
        textDecoration: 'none'
    }
}))
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const NewUser = (props) => {

    const classes = useStyles();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const admin_token = localStorage.getItem('admin_token')

    const [fullName, setfullName] = useState(null);
    const [position, setPosition] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [BirthDay, setBirthDay] = useState(null)
    const [Salary, setSalary] = useState(null)
    const [Commission, setCommission] = useState(null)
    const [HiredOn, setHiredOn] = useState(null)
    const [Passport, setPassport] = useState(null)
    const [fullNameError, setfullNameError] = useState('')
    const [positionError, setpositionError] = useState('')
    const [phoneError, setphoneError] = useState('')
    const [emailError, setemailError] = useState('')
    const [passwordError, setpasswordError] = useState('')
    const [conPassError, setconPassError] = useState('')
    const [BirthDayError, setBirthDayError] = useState('')
    const [CommissionError, setCommissionError] = useState('')
    const [SalaryError, setSalaryError] = useState('')
    const [HiredOnError, setHiredOnError] = useState('')


    const handleposition = (e) => {
        setPosition(e.target.value);
    };

    const handlefullname = (e) => {
        setfullName(e.target.value)
    }
    const handlePhone = (e) => {
        setPhoneNumber(e.target.value)
    }
    const handleEmail = (e) => {
        setEmailAddress(e.target.value)
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }
    const handlePassConfirm = (e) => {
        setConfirmPassword(e.target.value)
    }
    const handleCommission = (e) => {
        setCommission(e.target.value)
    }
    const handleSalary = (e) => {
        setSalary(e.target.value)
    }
    const handlePassport = (e) => {
        setPassport(e.target.value)
    }

    const history = useHistory()

    const handleAddEmployee = (e) => {
        e.preventDefault();

        axios.interceptors.request.use(
            config => {
                const admin_token = localStorage.getItem('admin_token')
                config.headers.authrization = `Bearer ${admin_token}`
                return config
            },
            error => {
                return Promise.reject(error)
            }
        )

        const admin_token = localStorage.getItem('admin_token')
        const admin_id = localStorage.getItem('admin_id')

        const data = {
            admin_id: admin_id,
            name: fullName,
            email: emailAddress,
            phone: phoneNumber,
            position: position,
            password: password,
            confirm_password: confirmPassword,
            commission: Commission,
            salary: Salary,
            hired_on: HiredOn,
            passport: Passport
        };

        axios.post('api/admin/v1/signupaccount', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + admin_token
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    history.push('/Admin-Dashboard')
                } else if (res.data.status === 201) {
                    setemailError(res.data.errors.email)
                    setpasswordError(res.data.errors.password)
                    setconPassError(res.data.errors.confirm_password)
                    setphoneError(res.data.errors.phone)
                    setpositionError(res.data.errors.position)
                    setfullNameError(res.data.errors.name)
                    setBirthDayError(res.data.errors.date_of_birthday)
                    setSalaryError(res.data.errors.salary)
                    setCommissionError(res.data.errors.commission)
                    setHiredOnError(res.data.errors.hired_on)
                }
            }, error => {
                console.log(error)
            })
    }

    const handleLogout = (e) => {

        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const admin_token = localStorage.getItem('admin_token');
            config.headers.Authorization = `Bearer ${admin_token}`;
            return config
        }, error => {
            return console.log(error)
        })

        const admin_token = localStorage.getItem('admin_token')
        axios.put('api/admin/v1/signout', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + admin_token
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.clear()
                    history.push('/Admin-Login')
                }
            })
    }

    const [Employees, setEmployees] = React.useState(false);
    const [Tasks, setTasks] = useState(false)
    const [Campaigns, setCampaigns] = useState(false)
    const [Leads, seTLeads] = useState(false)
    const [Projects, setProjects] = useState(false)
    const [Productss, setProductss] = useState(false)

    const handleEmployees = () => {
        setEmployees(!Employees);
    };
    const handleTasks = () => {
        setTasks(!Tasks);
    };
    const handleCampaigns = () => {
        setCampaigns(!Campaigns);
    };
    const handleLeads = () => {
        seTLeads(!Leads);
    };
    const handleProjects = () => {
        setProjects(!Projects)
    }
    const handleProducts = () => {
        setProductss(!Productss)
    }



    return (
        <div>
            <AppBar position="fixed" color='inherit' elevation='0' open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar alt='Current User' sx={{ width: 48, height: 48 }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem><LinkRouter className={classes.link} to='/Admin-Profile'>Profile</LinkRouter></MenuItem>
                            <MenuItem onClick={handleLogout} ><LinkRouter to='/'>Sign Out</LinkRouter></MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </DrawerHeader>
                <List>
                    <ListItem >
                        <ListItemIcon>
                            <DashboardRoundedIcon />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Admin-Dashboard' className={classes.link}>Dashboard</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItemButton onClick={handleLeads}>
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Leads" />
                        {Leads ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Leads} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Lead' className={classes.link}>New Lead</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <People />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Leads' className={classes.link}>Leads List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem >
                        <ListItemIcon>
                            <Contacts />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Admin-Contacts' className={classes.link}>Clients</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItemButton onClick={handleTasks}>
                        <ListItemIcon>
                            <AddTask />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Tasks" />
                        {Tasks ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Tasks} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <AddTask />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Task' className={classes.link}>New Task</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <TaskAlt />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Tasks' className={classes.link}>Tasks List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <DoneAll />
                                </ListItemIcon>
                                <ListItemText className={classes.link} >
                                    Finished Tasks
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleEmployees}>
                        <ListItemIcon>
                            <Work />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Employees" />
                        {Employees ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Employees} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/New-Employee' className={classes.linkActive}>Add employee</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Work />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Accounts' className={classes.link}>Employees List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Block />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Blocked-Employees' className={classes.link}>Blocked</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleCampaigns}>
                        <ListItemIcon>
                            <Campaign />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Campaigns" />
                        {Campaigns ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Campaigns} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Campaign' className={classes.link}>New Campaign</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Campaign />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Campaigns' className={classes.link}>Campaigns List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleProjects}>
                        <ListItemIcon>
                            <Construction />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Projects" />
                        {Projects ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Projects} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Create-Project' className={classes.link}>New Project</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Construction />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Projects' className={classes.link}>Projects List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleProducts}>
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Products" />
                        {Productss ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Productss} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Create-Product' className={classes.link}>New Product</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Business />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Products' className={classes.link}>Products List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Drawer>
            <Container component="main" sx={{ marginTop: "100px" }}>
                <Typography variant='h6'>
                    Required Fields
                </Typography>
                <form noValidate>
                    <Grid container spacing={2} columnSpacing={5}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handlefullname}
                                label='Full Name'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {fullNameError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleEmail}
                                label='Email Address'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {emailError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handlePhone}
                                label='Phone Number'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {phoneError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ marginTop: "16px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Position</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    label="position"
                                    size='medium'
                                    onChange={handleposition}
                                    onBlur={handleposition}
                                >
                                    <MenuItem value={"Head of Sales"}>Head of Sales</MenuItem>
                                    <MenuItem value={"Tele Sales"}>Tele Sales</MenuItem>
                                    <MenuItem value={"Sales"}>Sales</MenuItem>
                                    <MenuItem value={"Head of Marketing"}>Head of Marketing</MenuItem>
                                    <MenuItem value={"Finance"}>Finance</MenuItem>
                                    <MenuItem value={"Receptionist"}>Receptionist</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{ color: 'red' }}> {positionError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handlePassword}
                                label='Password'
                                type="password"
                            />
                            <span style={{ color: 'red' }}> {passwordError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handlePassConfirm}
                                label='Confirm Password'
                                type="password"
                            />
                            <span style={{ color: 'red' }}> {conPassError} </span>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 5 }} />
                    <Typography variant='h5'>
                        Optional Fields
                    </Typography>
                    <Grid container spacing={2} columnSpacing={5}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onBlur={handleSalary}
                                label='Salary'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {SalaryError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <TextField
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onBlur={handleCommission}
                                label='Commission'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {CommissionError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Birth date"
                                    views={['year', 'month', 'day']}
                                    inputFormat="yyyy-MM-dd"
                                    value={BirthDay}
                                    onChange={(BirthDay) => {
                                        setBirthDay(BirthDay.toLocaleDateString("sv-SE"))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <span style={{ color: 'red' }}> {BirthDayError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Hired on"
                                    views={['year', 'month', 'day']}
                                    inputFormat="yyyy-MM-dd"
                                    value={HiredOn}
                                    onChange={(HiredOn) => {
                                        setHiredOn(HiredOn.toLocaleDateString("sv-SE"))
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <span style={{ color: 'red' }}> {HiredOnError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FilePond
                                name={Passport}
                                onupdatefiles={Passport}
                                files={Passport}
                                server={{
                                    process: {
                                        url: 'http://sub.gulflandproperty.net/api/admin/v1/storepassport',
                                        method: 'POST',
                                        withCredentials: false,
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'AUTHORIZATION': 'Bearer ' + admin_token
                                        },
                                        ondata: (formData) => {
                                            formData.append('passport', Passport)
                                            console.log(console.log(Passport))
                                            return formData
                                        },
                                        onload: (res) => console.log(res.data)
                                    },
                                }}

                                allowMultiple={false}
                                instantUpload={false}
                                allowFileTypeValidation={true}
                                maxFiles={1}
                                labelIdle='Upload Passport Here (<span class="filepond--label-action"> PDF only </span>)'
                            />
                            <span style={{ color: 'red' }}> {HiredOnError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FilePond
                                allowMultiple={false}
                                maxFiles={1}
                                labelIdle='Upload Passport Here (<span class="filepond--label-action"> PDF only </span>)'
                            />
                            <span style={{ color: 'red' }}> {HiredOnError} </span>
                        </Grid>
                    </Grid>
                    <Box textAlign='center'>
                        <Button size='large' variant='contained' onClick={handleAddEmployee} sx={{ mt: 8, justifyContent: 'center', borderRadius: '24px' }}>Add Employee</Button>
                    </Box>
                </form>
            </Container>
        </div>
    );
}

export default NewUser;