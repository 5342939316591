import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Grid, Paper, Avatar, Menu, MenuItem, ListItemButton, Collapse } from '@mui/material'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { People, Contacts, ChevronLeft, ChevronRight, AddTask, Work, PersonAdd, Campaign, Add, ExpandLess, ExpandMore, Block, DoneAll, TaskAlt, Construction, Business } from '@mui/icons-material'
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormControl, Select, InputLabel } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import Swal from 'sweetalert2'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { styled, useTheme } from '@mui/material/styles';



const useStyles = makeStyles((theme) => ({
    Tables: {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        borderRadius: '25px',
    },
    logo: {
        maxWidth: 150,
        marginRight: '10px'
    },
    link: {
        textDecoration: 'none',
        color: '#bebebe'
    },
    linkActive: {
        color: '#000',
        textDecoration: 'none'
    }
}))

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const AdminNewCampaign = (props) => {

    const classes = useStyles();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [Name, setName] = useState('')
    const [Type, setType] = useState('')
    const [Status, setStatus] = useState('')
    const [StartDate, setStartDate] = useState('')
    const [EndDate, setEndDate] = useState('')
    const [Description, setDescription] = useState('')
    const [ExpectedRevenue, setExpectedRevenue] = useState('')
    const [BudgetCost, setBudgetCost] = useState('')
    const [ActualCost, setActualCost] = useState('')
    const [NameError, setNameError] = useState('')
    const [TypeError, setTypeError] = useState('')
    const [StatusError, setStatusError] = useState('')
    const [StarDateError, setStarDateError] = useState('')
    const [EndDateError, setEndDateError] = useState('')
    const [DescriptionError, setDescriptionError] = useState('')
    const [ExpectedRevError, setExpectedRevError] = useState('')
    const [BudgetCostError, setBudgetCostError] = useState('')
    const [ActualCostError, setActualCostError] = useState('')


    const handleName = (e) => {
        setName(e.target.value)
    }
    const handleType = (e) => {
        setType(e.target.value)
    }
    const handleStatus = (e) => {
        setStatus(e.target.value)
    }
    const handleDescription = (e) => {
        setDescription(e.target.value)
    }
    const handleExpectedCost = (e) => {
        setExpectedRevenue(e.target.value)
    }
    const handleBudgetCost = (e) => {
        setBudgetCost(e.target.value)
    }
    const handleActualCost = (e) => {
        setActualCost(e.target.value)
    }





    const history = useHistory()

    const handleLogout = (e) => {

        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const account_token = localStorage.getItem('account_token');
            config.headers.Authorization = `Bearer ${account_token}`;
            return config
        }, error => {
            return console.log(error)
        })

        const account_token = localStorage.getItem('account_token')
        axios.put('api/admin/v1/signout', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + account_token
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.clear()
                    history.push('/')
                }
            })
    }

    const handleAddLead = (e) => {
        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const admin_token = localStorage.getItem('admin_token')
            config.headers.Authorization = `Bearer ${admin_token}`
            return config
        }, error => {
            console.log(error)
        })

        const admin_token = localStorage.getItem('admin_token')
        const admin_id = localStorage.getItem('admin_id')

        const data = {
            name: Name,
            type: Type,
            status: Status,
            start_date: StartDate,
            end_date: EndDate,
            description: Description,
            expected_revenue: ExpectedRevenue,
            budgeted_cost: BudgetCost,
            actual_cost: ActualCost,
            admin_id: admin_id
        }

        axios.post('api/admin/v1/createcampaign', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + admin_token,
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Campaign Was Added Successfully',
                        showCancelButton: true,
                        cancelButtonText: 'close',
                        cancelButtonColor: '#CC3333',
                        confirmButtonText: 'Add New',
                        confirmButtonColor: '#42ba96'
                    }).then(result => {
                        if (result.isConfirmed) {
                            history.push('/Admin-New-Campaign')
                        } else if (result.isDenied) {
                            history.push('/Admin-Campaigns')
                        }
                    })
                } else if (res.data.status === 201) {
                    setNameError(res.data.errors.name)
                    setTypeError(res.data.errors.type)
                    setStatusError(res.data.errors.status)
                    setStarDateError(res.data.errors.start_date)
                    setEndDateError(res.data.errors.end_date)
                    setDescriptionError(res.data.errors.description)
                    setExpectedRevError(res.data.errors.expected_revenue)
                    setBudgetCostError(res.data.errors.budgeted_cost)
                    setActualCostError(res.data.errors.actual_cost)
                }
            }, error => {
                console.log(error)
            })
    }

    const [Employees, setEmployees] = React.useState(false);
    const [Tasks, setTasks] = useState(false)
    const [Campaigns, setCampaigns] = useState(false)
    const [Leadss, setLeadss] = useState(false)
    const [Projects, setProjects] = useState(false)
    const [Productss, setProductss] = useState(false)
    const handleEmployees = () => {
        setEmployees(!Employees);
    };
    const handleTasks = () => {
        setTasks(!Tasks);
    };
    const handleCampaigns = () => {
        setCampaigns(!Campaigns);
    };
    const handleLeadss = () => {
        setLeadss(!Leadss);
    };
    const handleProjects = () => {
        setProjects(!Projects)
    }
    const handleProducts = () => {
        setProductss(!Productss)
    }


    return (
        <div>
            <AppBar position="fixed" color='inherit' elevation='0' open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar   alt='Current User' sx={{ width: 48, height: 48 }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem><LinkRouter className={classes.link} to='/Admin-Profile'>Profile</LinkRouter></MenuItem>
                            <MenuItem onClick={handleLogout} ><LinkRouter to='/'>Sign Out</LinkRouter></MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </DrawerHeader>
                <List>
                    <ListItem >
                        <ListItemIcon>
                            <DashboardRoundedIcon />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Admin-Dashboard' className={classes.link}>Dashboard</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItemButton onClick={handleLeadss}>
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Leads" />
                        {Leadss ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Leadss} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Lead' className={classes.link}>New Lead</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <People />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Leads' className={classes.link}>Leads List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem >
                        <ListItemIcon>
                            <Contacts />
                        </ListItemIcon>
                        <ListItemText><LinkRouter to='/Admin-Contacts' className={classes.link}>Clients</LinkRouter></ListItemText>
                    </ListItem>
                    <ListItemButton onClick={handleTasks}>
                        <ListItemIcon>
                            <AddTask />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Tasks" />
                        {Tasks ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Tasks} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <AddTask />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Task' className={classes.link}>New Task</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <TaskAlt />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Tasks' className={classes.link}>Tasks List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <DoneAll />
                                </ListItemIcon>
                                <ListItemText className={classes.link} >
                                    Finished Tasks
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleEmployees}>
                        <ListItemIcon>
                            <Work />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Employees" />
                        {Employees ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Employees} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/New-Employee' className={classes.link}>Add employee</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Work />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Accounts' className={classes.link}>Employees List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Block />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Blocked-Employees' className={classes.link}>Blocked</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleCampaigns}>
                        <ListItemIcon>
                            <Campaign />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Campaigns" />
                        {Campaigns ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Campaigns} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Admin-New-Campaign' className={classes.linkActive}>New Campaign</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Campaign />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Campaigns' className={classes.link}>Campaigns List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleProjects}>
                        <ListItemIcon>
                            <Construction />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Projects" />
                        {Projects ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Projects} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Create-Project' className={classes.link}>New Project</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Construction />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Projects' className={classes.link}>Projects List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleProducts}>
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Products" />
                        {Productss ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Productss} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Create-Product' className={classes.link}>New Product</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Business />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Products' className={classes.link}>Products List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Drawer>
            <Container component="main" sx={{ marginTop: "100px" }}>
                <form noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            {/* first name container */}
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleName}
                                label='Name'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {NameError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ marginTop: '15px' }}>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={Type}
                                    label="Type"
                                    onChange={handleType}
                                >
                                    <MenuItem value={'Tele Marketing'}>Tele Marketing</MenuItem>
                                    <MenuItem value={'Advertisement'}>Advertisement</MenuItem>
                                    <MenuItem value={'Banner Ads'}>Banner Ads</MenuItem>
                                    <MenuItem value={'Webinar'}>Webinar</MenuItem>
                                    <MenuItem value={'Seminar'}>Seminar</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{ color: 'red' }}> {TypeError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Start Date"
                                    views={['year', 'month', 'day']}
                                    format='DD-MM-YYYY'
                                    disablePast='true'
                                    value={StartDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <span style={{ color: 'red' }}> {StarDateError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="End Date"
                                    views={['year', 'month', 'day']}
                                    format='DD-MM-YYYY'
                                    disablePast='true'
                                    value={EndDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <span style={{ color: 'red' }}> {EndDateError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ marginTop: '15px' }}>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    value={Status}
                                    label="Type"
                                    onChange={handleStatus}
                                >
                                    <MenuItem value={'Done'}>Done</MenuItem>
                                    <MenuItem value={'In Progress'}>In Progress</MenuItem>
                                    <MenuItem value={'Finished'}>Finished</MenuItem>
                                    <MenuItem value={'Shut Down'}>Shut Down</MenuItem>
                                </Select>
                            </FormControl>
                            <span style={{ color: 'red' }}> {StatusError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleExpectedCost}
                                label='Expected Revenue'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {ExpectedRevError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleBudgetCost}
                                label='Budgeted Cost'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {BudgetCostError} </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                onBlur={handleActualCost}
                                label='Actual Cost'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {ActualCostError} </span>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                required
                                multiline
                                rows='5'
                                fullWidth
                                onBlur={handleDescription}
                                label='Description'
                                type="text"
                            />
                            <span style={{ color: 'red' }}> {DescriptionError} </span>
                        </Grid>
                    </Grid>
                    <Box textAlign='center'>
                        <Button size='large' variant='contained' sx={{ my: 8, justifyContent: 'center', borderRadius: '24px' }} onClick={handleAddLead}>Add Lead</Button>
                    </Box>
                </form>
            </Container>
        </div>
    );
}

export default AdminNewCampaign;