import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom'
import { useState } from 'react';
import axios from 'axios'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, IconButton, InputAdornment, OutlinedInput, InputLabel, MenuItem, Select } from '@mui/material';
import Swal from 'sweetalert2';


const theme = createTheme();

export default function SignIn() {

  const history = useHistory()


  const [email, setemail] = useState(null)
  const [password, setpassword] = useState(null)
  const [emailError, setemailError] = useState('')
  const [passError, setPassError] = useState('')
  const [Position, setPosition] = useState('');

  const getEmail = (e) => {
    setemail(e.target.value)
  }
  const getPassword = (e) => {
    setpassword(e.target.value)
  }
  const handlePosition = (e) => {
    setPosition(e.target.value)
  }

  const handleUserLogin = (e) => {
    e.preventDefault();

    const data = {
      email: email,
      password: password
    }

    axios.post('api/account/v1/signin', data)
      .then(res => {
        if (res.data.status === 200 ) {
          localStorage.setItem('account_token', res.data.token)
          localStorage.setItem('account_id', res.data.account.id)
          history.push('/Dashboard')
        } else if (res.data.status === 201) {
          setemailError(res.data.errors.email)
          setPassError(res.data.errors.password)
        } else if (res.data.status === 202) {
          Swal.fire({
            title: 'Unknown Error',
            text: 'Check Email/Password And Try Again',
            icon: 'error'
          })
        }
      }, error => {
        console.log(error)
      })

  }

  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });


  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" autoComplete='on' noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onBlur={getEmail}
            />
            <span style={{ color: 'red' }}> {emailError} </span>
            <FormControl sx={{ mt: 3 }} defa fullWidth variant="outlined">
              <OutlinedInput
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onBlur={getPassword}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <span style={{ color: 'red' }}> {passError} </span>
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id="demo-simple-select-label">Position</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Position}
                label="Age"
                onChange={handlePosition}
              >
                <MenuItem value='Receptionist'>Receptionist</MenuItem>
                <MenuItem value='Tele Sales'>Tele Sales</MenuItem>
                <MenuItem value='Sales'>Sales</MenuItem>
                <MenuItem value='Marketing'>Marketing</MenuItem>
                <MenuItem value='Finance'>Finance</MenuItem>
              </Select>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleUserLogin}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}