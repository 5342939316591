import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Grid, Avatar, Menu, MenuItem, Collapse, ListItemButton } from '@mui/material'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { People, Contacts, AddTask, Work, PersonAdd, Campaign, ChevronLeft, ChevronRight, ExpandLess, ExpandMore, TaskAlt, DoneAll, Block, Add, Construction, Business } from '@mui/icons-material'
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Logo from '../../../Assets/images/logo.png'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import axios from 'axios'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Swal from 'sweetalert2'
import { createTheme } from '@mui/system'
import { ThemeProvider } from '@mui/private-theming'
import { styled, useTheme } from '@mui/material/styles';




const useStyles = makeStyles((theme) => ({
    Tables: {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        borderRadius: '25px',
    },
    logo: {
        maxWidth: 150,
        marginRight: '10px'
    },
    link: {
        textDecoration: 'none',
        color: '#bebebe'
    },
    linkActive: {
        color: '#000',
        textDecoration: 'none'
    }
}))

const theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
        ]
    },
    shape: {
        borderRadius: '24px'
    }
})

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));



const AdminTasks = (props) => {

    const [selectedRow, setSelectedRow] = useState();

    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute('data-id')));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    const classes = useStyles();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setContextMenu(null);
    };

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };


    const [tasks, setTasks] = useState([])

    const history = useHistory()

    const getTasks = (e) => {

        axios.interceptors.request.use((config) => {
            const admin_token = localStorage.getItem('admin_token')
            config.headers.Authorization = `Bearer ${admin_token}`
            return config
        }, error => {
            return Promise.reject(error)
        })

        const admin_token = localStorage.getItem('admin_token')

        axios.get("api/admin/v1/tasks", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer` + admin_token
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setTasks(res.data.tasks.data)
                }
            })

    }

    useEffect(() => {
        getTasks()
    }, [])

    const handleLogout = (e) => {

        e.preventDefault();

        axios.interceptors.request.use((config) => {
            const admin_token = localStorage.getItem('admin_token');
            config.headers.Authorization = `Bearer ${admin_token}`;
            return config
        }, error => {
            return console.log(error)
        })

        const admin_token = localStorage.getItem('admin_token')
        axios.put('api/admin/v1/signout', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + admin_token
            },
        })
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.clear()
                    history.push('/')
                }
            })
    }

    const Columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'subject', headerName: 'Subject', width: 100 },
        { field: 'due_date', headerName: 'Due Date', width: 120 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'priority', headerName: 'Priority', width: 150 },
        { field: 'description', headerName: 'Description', width: 150 },
    ]

    const [taskId, settaskId] = useState({})

    const handleTaskDelete = (e) => {

        axios.interceptors.request.use((config) => {
            const admin_token = localStorage.getItem('admin_token')
            config.headers.Authorization = `Bearer ${admin_token}`
            return config
        }, error => {
            console.log(error)
        })

        const admin_token = localStorage.getItem('admin_token')

        const data = {
            id: taskId
        }

        axios.post('api/admin/v1/deletetask', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + admin_token
            }
        })
            .then(res => {
                if (res.status === 200) {
                    history.go(0)
                }
            })
    }

    const popUpDelete = (e) => {

        Swal.fire({
            icon: 'warning',
            title: 'Delete Task',
            text: 'Are you Sure you want to delete Task ?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Delete',
            confirmButtonColor: '#df4759'

        })
            .then(result => {
                if (result.isConfirmed) {
                    handleTaskDelete()
                }
            })
    }

    const [Employees, setEmployees] = React.useState(false);
    const [Taskss, setTaskss] = useState(false)
    const [Campaigns, setCampaigns] = useState(false)
    const [Leadss, setLeadss] = useState(false)
    const [Projects, setProjects] = useState(false)
    const [Productss, setProductss] = useState(false)
    const handleEmployees = () => {
        setEmployees(!Employees);
    };
    const handleTasks = () => {
        setTaskss(!Taskss);
    };
    const handleCampaigns = () => {
        setCampaigns(!Campaigns);
    };
    const handleLeadss = () => {
        setLeadss(!Leadss);
    };
    const handleProjects = () => {
        setProjects(!Projects)
    }
    const handleProducts = () => {
        setProductss(!Productss)
    }


    return (
        <ThemeProvider>
            <div>
                <AppBar position="fixed" color='inherit' elevation='0' open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        </Typography>
                        <Button variant="outlined" color="primary">
                            <LinkRouter className={classes.link} to='/Admin-New-Task'>New Task</LinkRouter>
                        </Button>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <Avatar alt='Current User' sx={{ width: 48, height: 48 }} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem><LinkRouter className={classes.link} to='/Admin-Profile'>Profile</LinkRouter></MenuItem>
                                <MenuItem onClick={handleLogout} ><LinkRouter to='/'>Sign Out</LinkRouter></MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                    </DrawerHeader>
                    <List>
                        <ListItem >
                            <ListItemIcon>
                                <DashboardRoundedIcon />
                            </ListItemIcon>
                            <ListItemText><LinkRouter to='/Admin-Dashboard' className={classes.link}>Dashboard</LinkRouter></ListItemText>
                        </ListItem>
                        <ListItemButton onClick={handleLeadss}>
                            <ListItemIcon>
                                <People />
                            </ListItemIcon>
                            <ListItemText className={classes.link} primary="Leads" />
                            {Leadss ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={Leadss} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <PersonAdd />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <LinkRouter to='/Admin-New-Lead' className={classes.link}>New Lead</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <People />
                                    </ListItemIcon>
                                    <ListItemText >
                                        <LinkRouter to='/Admin-Leads' className={classes.link}>Leads List</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem >
                            <ListItemIcon>
                                <Contacts />
                            </ListItemIcon>
                            <ListItemText><LinkRouter to='/Admin-Contacts' className={classes.link}>Clients</LinkRouter></ListItemText>
                        </ListItem>
                        <ListItemButton onClick={handleTasks}>
                            <ListItemIcon>
                                <AddTask />
                            </ListItemIcon>
                            <ListItemText className={classes.link} primary="Tasks" />
                            {Taskss ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={Taskss} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <AddTask />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <LinkRouter to='/Admin-New-Task' className={classes.link}>New Task</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <TaskAlt />
                                    </ListItemIcon>
                                    <ListItemText >
                                        <LinkRouter to='/Admin-Tasks' className={classes.linkActive}>Tasks List</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <DoneAll />
                                    </ListItemIcon>
                                    <ListItemText className={classes.link} >
                                        Finished Tasks
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleEmployees}>
                            <ListItemIcon>
                                <Work />
                            </ListItemIcon>
                            <ListItemText className={classes.link} primary="Employees" />
                            {Employees ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={Employees} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <PersonAdd />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <LinkRouter to='/New-Employee' className={classes.link}>Add employee</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <Work />
                                    </ListItemIcon>
                                    <ListItemText >
                                        <LinkRouter to='/Accounts' className={classes.link}>Employees List</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <Block />
                                    </ListItemIcon>
                                    <ListItemText >
                                        <LinkRouter to='/Blocked-Employees' className={classes.link}>Blocked</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleCampaigns}>
                            <ListItemIcon>
                                <Campaign />
                            </ListItemIcon>
                            <ListItemText className={classes.link} primary="Campaigns" />
                            {Campaigns ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={Campaigns} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <Add />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <LinkRouter to='/Admin-New-Campaign' className={classes.link}>New Campaign</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <Campaign />
                                    </ListItemIcon>
                                    <ListItemText >
                                        <LinkRouter to='/Admin-Campaigns' className={classes.link}>Campaigns List</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleProjects}>
                            <ListItemIcon>
                                <Construction />
                            </ListItemIcon>
                            <ListItemText className={classes.link} primary="Projects" />
                            {Projects ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={Projects} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <Add />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <LinkRouter to='/Create-Project' className={classes.link}>New Project</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <Construction />
                                    </ListItemIcon>
                                    <ListItemText >
                                        <LinkRouter to='/Admin-Projects' className={classes.link}>Projects List</LinkRouter>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleProducts}>
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText className={classes.link} primary="Products" />
                        {Productss ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={Productss} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Add />
                                </ListItemIcon>
                                <ListItemText>
                                    <LinkRouter to='/Create-Product' className={classes.link}>New Product</LinkRouter>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <Business />
                                </ListItemIcon>
                                <ListItemText >
                                    <LinkRouter to='/Admin-Products' className={classes.link}>Products List</LinkRouter>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    </List>
                </Drawer>

                <Container sx={{ marginTop: '100px' }}>
                    <Typography
                        sx={{ mb: 3 }}
                        variant="h4"
                    >
                        All Tasks
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} sx={{ mt: 8, mb: 8 }}>
                            <div style={{ height: 800, width: '100%' }}>
                                <DataGrid
                                    rows={tasks}
                                    columns={Columns}
                                    checkboxSelection
                                    density='comfortable'
                                    selected
                                    className={classes.Tables}
                                    onSelectionModelChange={(NewSelectionModel) => {
                                        settaskId(parseInt(NewSelectionModel))
                                    }}
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                    componentsProps={{
                                        row: {
                                            onContextMenu: handleContextMenu,
                                            style: { cursor: 'context-menu' },
                                        },
                                    }}
                                />
                            </div>

                            <Menu
                                open={contextMenu !== null}
                                onSelect={localStorage.setItem('lead_id', Columns.id)}
                                onClose={handleClose}
                                anchorReference="anchorPosition"
                                anchorPosition={
                                    contextMenu !== null
                                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                        : undefined
                                }
                                componentsProps={{
                                    root: {
                                        onContextMenu: (e) => {
                                            e.preventDefault();
                                            handleClose();
                                        },
                                    },
                                }}
                            >
                                <MenuItem>Edit Task information</MenuItem>
                                <MenuItem onClick={popUpDelete} >Delete Task</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default AdminTasks;